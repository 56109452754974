import AddressAutoComplete from '../_lib/_address-auto-complete';
import ReCaptcha from '../_lib/_recaptcha';

/**
 * @param {HTMLFormElement} form
 */
function setupReCaptcha(form) {
  if (!window.grecaptcha) {
    return;
  }

  const reCapture = new ReCaptcha(window.grecaptcha, form, 'order[recaptcha_token]');

  form.addEventListener('submit', e => {
    e.preventDefault();

    reCapture.submit();
  });
}

function initializeOrderForm() {
  const form = document.querySelector('form.repair-form');

  new AddressAutoComplete({
    postalCode: form.querySelector('input[name="order[postal_code]"]'),
    prefecture: form.querySelector('select[name="order[prefecture_id]"]'),
    city: form.querySelector('input[name="order[city]"]'),
    street: form.querySelector('input[name="order[street]"]'),
  });

  setupReCaptcha(form);
}

export default initializeOrderForm;

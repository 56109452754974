const API_BASE_URL = 'https://zipcloud.ibsnet.co.jp/api/search';

/**
 * @typedef ZipCloudSearchResponse
 * @property {string} status
 * @property {string} message
 * @property {ZipCloudSearchResponseResult[]} results
 */

/**
 * @typedef ZipCloudSearchResponseResult
 * @property {string} zipcode
 * @property {string} prefcode
 * @property {string} address1
 * @property {string} address2
 * @property {string} address3
 * @property {string} kana1
 * @property {string} kana2
 * @property {string} kana3
 *
 */

class AddressAutoComplete {
  /**
   * @type {HTMLInputElement}
   */
  postalCodeInput = undefined;

  /**
   * @type {HTMLInputElement|HTMLSelectElement}
   */
  prefectureInput = undefined;

  /**
   * @type {HTMLInputElement}
   */
  cityInput = undefined;

  /**
   * @type {HTMLInputElement}
   */
  streetInput = undefined;

  /**
   * @type {HTMLInputElement}
   */
  buildingInput = undefined;

  /**
   * @type {number}
   */
  postalCodeInputDelayTimer = undefined;

  /**
   * @type {number}
   */
  postalCodeInputDelay = 500;

  /**
   * @param {object} options
   * @param {HTMLInputElement} options.postalCode
   * @param {HTMLInputElement|HTMLSelectElement?} options.prefecture
   * @param {HTMLInputElement?} options.city
   * @param {HTMLInputElement?} options.street
   * @param {HTMLInputElement?} options.building
   * @param {number?} options.postalCodeInputDelay
   */
  constructor(options) {
    this.postalCodeInput = options.postalCode;
    this.prefectureInput = options.prefecture;
    this.cityInput = options.city;
    this.streetInput = options.street;
    this.buildingInput = options.building;

    if (options.postalCodeInputDelay) {
      this.postalCodeInputDelay = options.postalCodeInputDelay;
    }

    this.start();
  }

  /**
   * @param {string} postalCode
   * @erturn {Promise}
   */
  buildRequest(postalCode) {
    const url = new URL(API_BASE_URL);
    url.searchParams.set('zipcode', postalCode);

    return fetch(url, {
      method: 'GET',
    });
  }

  /**
   * @param {ZipCloudSearchResponseResult} result
   */
  updateResult(result) {
    if (this.prefectureInput) {
      this.prefectureInput.value = result.prefcode || '';
    }

    if (this.cityInput) {
      this.cityInput.value = result.address2;
    }

    if (this.streetInput) {
      this.streetInput.value = result.address3;
    }

    if (this.buildingInput) {
      this.buildingInput.value = '';
    }
  }

  clearResult() {
    this.updateResult({
      zipcode: null,
      prefcode: null,
      address1: null,
      address2: null,
      address3: null,
      kana1: null,
      kana2: null,
      kana3: null,
    });
  }

  /**
   * @param {string} raw
   * @return {string}
   */
  normalizePostalCode(raw) {
    return raw.replaceAll(/^ +| +$|-/g, '');
  }

  start() {
    this.postalCodeInput.addEventListener('input', () => {
      const postalCode = this.normalizePostalCode(this.postalCodeInput.value);

      if (postalCode.length !== 7) {
        this.clearResult();
        return;
      }

      if (this.postalCodeInputDelayTimer) {
        clearTimeout(this.postalCodeInputDelayTimer);
      }

      this.postalCodeInputDelayTimer = setTimeout(
        () => {
          this.onPostalCodeChanged(postalCode)
        },
        this.postalCodeInputDelay
      );
    });
  }

  onPostalCodeChanged(postalCode) {
    this.buildRequest(postalCode)
      .then(response => {
        return response.json();
      })
      .then((response) => {
        if (response.status !== 200) {
          return;
        }

        if (response.results.length === 0) {
          this.clearResult();
          return;
        }

        this.updateResult(response.results[0]);
      });
  }
}

export default AddressAutoComplete;

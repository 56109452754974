class ReCapture {
  /**
   * @type {object}
   * @see https://developers.google.com/recaptcha/docs/v3
   */
  engine = undefined;

  /**
   * @type {string}
   */
  siteKey = undefined;

  /**
   * @type {HTMLFormElement}
   */
  form = undefined;

  /**
   * @type {string}
   */
  tokenInputName = undefined;

  /**
   * @param {object} engine
   * @param {HTMLFormElement} form
   * @param {string} tokenInputName
   */
  constructor(engine, form, tokenInputName) {
    this.engine = engine;
    this.form = form;
    this.tokenInputName = tokenInputName;
    this.siteKey = this.getSiteKey();
  }

  submit() {
    this.engine.ready(() => {
      this.engine.execute(this.siteKey, {action: 'submit'})
        .then(token => {
          const tokenInput = this.form.ownerDocument.createElement('input');
          tokenInput.type = 'hidden';
          tokenInput.name = this.tokenInputName;
          tokenInput.value = token;
          this.form.appendChild(tokenInput);
          this.form.submit();
        });
    });
  }

  /**
   *
   * @return {string}
   */
  getSiteKey() {
    console.log(this.form.ownerDocument.querySelector('script#recaptcha'));
    const url = new URL(this.form.ownerDocument.querySelector('script#recaptcha').src);

    return url.searchParams.get('render');
  }
}

export default ReCapture;

import Swiper from 'swiper';
import {Navigation, Autoplay, Pagination} from 'swiper/modules';
import initializeRepairForm from '../repair-form/_form';

function initializeMainVisual() {
  new Swiper('.mainvisual .swiper', {
    modules: [Navigation, Autoplay, Pagination],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: '1',
    centeredSlides: true,
    autoHeight: true,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    loop: true,
    breakpoints: {
      // スライドの表示枚数：768px以上の場合
      768: {
        slidesPerView: 1.3,
      }
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initializeMainVisual();
  initializeRepairForm();
});
